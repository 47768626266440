import React, {useEffect, useState} from 'react';
import {Button, DropdownItemProps} from "semantic-ui-react";
import {useHistory} from "react-router-dom";
import {DartsGame} from "../../../../store/pages/schedule/types";
import moment from "moment/moment";
import {useAppDispatch} from "../../../../hooks/hooks";
import {setIsFinalDailyResult} from "../../../../store/dailyResults/dailyResultSlice";

interface IScheduleInformButtonsProps {
    gamesOptions: DropdownItemProps[] | undefined
    selectedGame: number
    selectedTournament: number
    selectedADCGame:number
    games: DartsGame[]
}

const ScheduleInformButtons: React.FC<IScheduleInformButtonsProps> = (
    {
        gamesOptions,
        selectedGame,
        selectedTournament,
        games

    }) => {
    const dispatch = useAppDispatch()
    const [currentGame, setCurrentGame] = useState<null | DartsGame>(null);
    const history = useHistory();

    useEffect(() => {
        if (selectedGame) {
            const game = games.find(item => item.id === selectedGame)
            if (game) {
                setCurrentGame(game)
            }
        }
    }, [selectedGame]);

    const goToLeagueList = () => {
        history.push(`/darts/leagues/${selectedTournament}`);
    };

    const gotToPreMatchAnimation = () => {
        history.push(`/pre-match-animation/${selectedGame}`);
    };

    const gotToPreMatchStats = () => {
        history.push(`/pre-match-stats/${selectedGame}`);
    };

    const gotToPostMatch = () => {
        history.push(`post-match-page/${selectedGame}`);
    };

    const goToDailyResult = (currentGame: DartsGame | null) => {
        if (currentGame) {
            history.push(`daily-results/${moment(currentGame.date).format("YYYY-MM-DD")}/${selectedTournament}`);
            dispatch(setIsFinalDailyResult(null))
        }
    };

    return (
        <div className="schedule__login">
            {!!(selectedTournament && selectedGame && gamesOptions !== undefined && gamesOptions.length > 0) &&
                <>
                    <Button
                        disabled={!selectedGame}
                        id="league_table"
                        color="brown"
                        onClick={goToLeagueList}
                    >
                        League table
                    </Button>
                    <Button
                        disabled={!selectedGame}
                        id="league_table"
                        color="olive"
                        onClick={() => goToDailyResult(currentGame)}
                    >
                        Daily results
                    </Button>
                    {/*<Button*/}
                    {/*    id="scoreboard"*/}
                    {/*    disabled={!selectedGame}*/}
                    {/*    color="blue"*/}
                    {/*    onClick={gotToPreMatchAnimation}>*/}
                    {/*    Pre-Match Animation*/}
                    {/*</Button>*/}
                    <Button
                        id="scoreboard"
                        disabled={!selectedGame}
                        color="facebook"
                        onClick={gotToPreMatchStats}>
                        Pre-Match Image
                    </Button>
                    <Button
                        id="scoreboard"
                        color="violet"
                        onClick={gotToPostMatch}>
                        Post-Match
                    </Button>
                </>
            }
        </div>
    );
};

export default ScheduleInformButtons;

import React, {useEffect, useState} from "react";
import {Button, Container, Icon, Progress} from "semantic-ui-react";
import Flatpickr from "react-flatpickr";
import {client} from "../../services/api.service";
import fileDownload from "js-file-download";
import {useHistory} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import {setDownloadedGames, setTotalGamesForDownloading} from "../../store/pages/schedule/scheduleSlice";
import "./style.scss";

export const Statistics = () => {
    const dispatch = useAppDispatch()
    const history = useHistory()
    const [startDate, setStartDate] = useState<string | Date>(new Date());
    const [endDate, setEndDate] = useState<string | Date>(new Date());
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const {totalGamesForDownloading, downloadedGames} = useAppSelector((state) => state.schedule);

    useEffect(() => {
        if (totalGamesForDownloading === downloadedGames && totalGamesForDownloading !== 0) {
            getCSVStatistics()
        }
    }, [totalGamesForDownloading, downloadedGames])

    const getTotalGamesAndOpenSocket = async (from: string | Date, to: string | Date) => {
        const body = {from, to,};
        dispatch(setDownloadedGames(0))
        setIsLoading(true);
        dispatch(setTotalGamesForDownloading(0))
        const {data} = await client.post("/api/darts-statistics/form-statistics", body);
        if(data === 0) {
            setIsLoading(false);
            return alert("There no games for this period")

        }

        dispatch(setTotalGamesForDownloading(data))
        setIsLoading(false);
    };

    const getCSVStatistics = async () => {
        setIsLoading(true);
        try {
            const response = await client.get("/api/darts-statistics/get-csv-file", {
                responseType: 'blob',
            });

            const contentDisposition = response.headers['content-disposition'];
            let fileName = 'statistics.csv';

            if (contentDisposition) {
                const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
                if (fileNameMatch && fileNameMatch[1]) {
                    fileName = fileNameMatch[1];
                }
            }

            fileDownload(response.data, fileName);
        } catch (error) {
            console.error("Something went wrong", error);
        } finally {
            setIsLoading(false);
            setTimeout(() => {
                dispatch(setTotalGamesForDownloading(0));
            }, 1000);
        }
    };

    const dayPickerOptions = {
        dateFormat: "Y-m-d",
        locale: {
            firstDayOfWeek: 1,
        },
    };

    return (
        <Container>
            <div onClick={() => history.go(-1)} className="back-arrow">
                <div>
                    <Icon className="back-arrow__icon" name="arrow left"/>
                </div>
                <p className="back-arrow__text">Back to Schedule</p>
            </div>
            <div className="statistics">
                <div className="statistics-inputs">
                    <div className="statistics__datepicker">
                        <label>Start date:</label>
                        <Flatpickr
                            className="statistics__flatpickr"
                            value={startDate}
                            options={dayPickerOptions}
                            onChange={(date: Date, dayStr: string) => setStartDate(dayStr)}
                        />
                    </div>
                    <div className="statistics__datepicker">
                        <label>End date:</label>
                        <Flatpickr
                            className="statistics__flatpickr"
                            value={endDate}
                            options={dayPickerOptions}
                            onChange={(date: Date, dayStr: string) => setEndDate(dayStr)}
                        />
                    </div>
                </div>
                <div className="statistics__download-btn">
                    <Button
                        loading={isLoading}
                        onClick={() => getTotalGamesAndOpenSocket(startDate, endDate)}
                        color="green"
                    >
                        Download Stats
                    </Button>
                </div>
            </div>
            {totalGamesForDownloading !== null && totalGamesForDownloading !== 0  && (
                <Progress
                    color="green"
                    progress="value"
                    value={downloadedGames}
                    total={totalGamesForDownloading}/>
            )}
        </Container>
    );
};

export default Statistics;
